div.outerDiv {
  background-image: url("../../assets/images/cook.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div.innerDiv {
    img {
      width: 300px;
      color: white;
    }
    height: fit-content;
    width: 350px;
    backdrop-filter: blur(50px);
    background-color: rgba(248, 183, 55, 0.5);
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;
    padding: 12px;

    p {
      text-align: center;
    }
  }
}
